import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3cc2e736 = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _836e85ea = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _f487e952 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _246b5d62 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _717f9602 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _5b0d61a9 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _1a58c73e = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _5bd8ea21 = () => interopDefault(import('../pages/chichester-wedding-flowers/index.vue' /* webpackChunkName: "pages/chichester-wedding-flowers/index" */))
const _41338577 = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _2e388076 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _6d1325e4 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _dec4d200 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _7bb8d160 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _4cb24364 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _48cb21cc = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _7473c024 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _484b1f9f = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _7336a5b0 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _03a3212a = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _5d761762 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _4f18c4a1 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _c1644b5e = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _5a2248d9 = () => interopDefault(import('../pages/chichester-wedding-flowers/_slug.vue' /* webpackChunkName: "pages/chichester-wedding-flowers/_slug" */))
const _0ec6f8ad = () => interopDefault(import('../pages/Pages/_slug.vue' /* webpackChunkName: "pages/Pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _3cc2e736,
    name: "cart___default"
  }, {
    path: "/checkout",
    component: _836e85ea,
    name: "checkout___default",
    children: [{
      path: "billing",
      component: _f487e952,
      name: "billing___default"
    }, {
      path: "payment",
      component: _246b5d62,
      name: "payment___default"
    }, {
      path: "shipping",
      component: _717f9602,
      name: "shipping___default"
    }, {
      path: "thank-you",
      component: _5b0d61a9,
      name: "thank-you___default"
    }, {
      path: "user-account",
      component: _1a58c73e,
      name: "user-account___default"
    }]
  }, {
    path: "/chichester-wedding-flowers",
    component: _5bd8ea21,
    name: "chichester-wedding-flowers___default"
  }, {
    path: "/Cms",
    component: _41338577,
    name: "Cms___default"
  }, {
    path: "/customer",
    component: _2e388076,
    meta: {"titleLabel":"My Account"},
    name: "customer___default",
    children: [{
      path: "addresses-details",
      component: _6d1325e4,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___default"
    }, {
      path: "my-newsletter",
      component: _dec4d200,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___default"
    }, {
      path: "my-profile",
      component: _7bb8d160,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___default"
    }, {
      path: "my-reviews",
      component: _4cb24364,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___default"
    }, {
      path: "my-wishlist",
      component: _48cb21cc,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___default"
    }, {
      path: "order-history",
      component: _7473c024,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___default"
    }, {
      path: "/reset-password",
      component: _484b1f9f,
      alias: "/customer/account/createPassword",
      name: "reset-password___default"
    }, {
      path: "addresses-details/create",
      component: _7336a5b0,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _03a3212a,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___default"
    }, {
      path: "order-history/:orderId",
      component: _5d761762,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___default"
    }]
  }, {
    path: "/Home",
    component: _4f18c4a1,
    name: "Home___default"
  }, {
    path: "/Page",
    component: _c1644b5e,
    name: "Page___default"
  }, {
    path: "/chichester-wedding-flowers/:slug?",
    component: _5a2248d9,
    name: "chichester-wedding-flowers-slug___default"
  }, {
    path: "/Pages/:slug?",
    component: _0ec6f8ad,
    name: "Pages-slug___default"
  }, {
    path: "/",
    component: _4f18c4a1,
    name: "home___default"
  }, {
    path: "/:slug+",
    component: _c1644b5e,
    name: "page___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
